@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App {
  text-align: center;
}

html, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

.dashboard-content {
  max-width: 1200px;
  max-height: fit-content;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.metric-selector {
  margin-bottom: 20px;
}

.metric-selector label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.metric-selector select {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.chart-container {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

button:hover {
  background-color: #0056b3;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.time-range-slider {
  width: 100%;
  margin-top: 20px;
}

.time-range-slider input {
  width: 100%;
}

.time-range-slider div {
  font-weight: bold;
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.main-content {
  flex: 1;
  overflow-y: auto;
}

.dark {
  background-color: #1a202c;
  color: #e2e8f0;
}

.marker-cluster-custom {
  background-color: #1e88e5;
  color: white;
}

.marker-cluster-violated {
  background-color: #e53935;
  color: white;
}

.marker-cluster-custom div,
.marker-cluster-violated div {
  background-color: rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;
  text-align: center;
  border-radius: 15px;
  font-size: 12px;
  line-height: 30px;
}
.custom-div-icon svg {
  width: 100%;
  height: 100%;
}

.halo-icon svg {
  width: 100%;
  height: 100%;
}

.network-popup.dark .leaflet-popup-content-wrapper {
  background-color: rgba(31, 41, 55, 0.95);
  color: white;
}

.network-popup.dark .leaflet-popup-tip {
  background-color: rgba(31, 41, 55, 0.95);
}

.network-popup .leaflet-popup-content-wrapper {
  border-radius: 8px;
  padding: 0;
  overflow: hidden;
}

.network-popup .leaflet-popup-content {
  margin: 0;
  min-width: 200px;
}

.overview-button {
  position: absolute;
  left: 50%;
  bottom: 24px;
  transform: translateX(-50%);
  z-index: 1000;
  padding: 8px 16px;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.overview-button:hover {
  transform: translateX(-50%) translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.asset-selection-popup .leaflet-popup-content-wrapper {
  padding: 0;
  overflow: hidden;
}

.asset-selection-popup .leaflet-popup-content {
  margin: 0;
  min-width: 200px;
}

.asset-selection-popup button:hover {
  transition: all 0.2s;
}

.leaflet-tooltip {
  padding: 0 !important;
  border: none !important;
  border-width: 0 !important;
  background-color: transparent !important;
  box-shadow: none !important;
  pointer-events: none !important;
}

.dark-tooltip,
.light-tooltip {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  pointer-events: none;
}

.dark-tooltip {
  background-color: #2d3748 !important;
  color: #f7fafc !important;
}

.light-tooltip {
  background-color: white !important;
  color: #1a202c !important;
}

/* Remove all tooltip arrows */
.leaflet-tooltip:before,
.leaflet-tooltip-top:before, 
.leaflet-tooltip-bottom:before, 
.leaflet-tooltip-left:before, 
.leaflet-tooltip-right:before {
  display: none !important;
  border: none !important;
  content: none !important;
}

/* Custom tooltip for lines to avoid black box issue */
.custom-tooltip {
  position: absolute;
  z-index: 1000;
  border-radius: 4px;
  padding: 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  pointer-events: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.dark-custom-tooltip {
  background-color: #2d3748;
  color: #f7fafc;
}

.light-custom-tooltip {
  background-color: white;
  color: #1a202c;
}

/* Common tooltip content styling */
.tooltip-content {
  padding: 8px 12px;
  min-width: 150px;
  pointer-events: none;
}

.tooltip-header {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 4px;
  border-bottom: 1px solid rgba(128, 128, 128, 0.2);
  padding-bottom: 3px;
}

.tooltip-row {
  font-size: 12px;
  line-height: 1.5;
}

.tooltip-violated {
  color: #f56565;
  font-weight: 500;
}

.tooltip-battery {
  background-color: rgba(76, 175, 80, 0.15);
  border-left: 3px solid #4CAF50;
  padding: 4px 6px;
  margin-top: 4px;
  margin-bottom: 2px;
  border-radius: 2px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

/* Light theme tooltip */
.light-tooltip .tooltip-battery {
  color: #2E7D32;
}

/* Dark theme tooltip */
.dark-tooltip .tooltip-battery {
  color: #81C784;
  background-color: rgba(76, 175, 80, 0.25);
}

/* Battery marker pulse animation */
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.custom-div-icon-battery-marker-icon svg {
  width: 100%;
  height: 100%;
  animation: pulse 2s infinite ease-in-out;
}

